
import React, { Fragment } from "react"
import { StaticQuery, graphql } from "gatsby"
import Tag from "./tag"
// import Image from "gatsby-image"


function Category() {
  return (
    <StaticQuery
      query={catQuery}
      render={({ allMarkdownRemark: { edges } }) => {
        const tags = edges.reduce((pre, cur) => {
          return [...new Set(pre.concat(cur.node.frontmatter.tags))];
        }, [])

        return (
          <Fragment>
            <h4>所有标签</h4>
            <div>
              {
                tags.map(v => (
                  <Tag key={v} tag={v} />
                ))
              }
            </div>
          </Fragment>
        )
      }}
    />
  )
}

const catQuery = graphql`
query {
  allMarkdownRemark(filter: { frontmatter: { state: { eq: "on" }}}) {
    edges {
      node {
        frontmatter {
          tags
        }
      }
    }
  }
}
`

export default Category


import React, { Fragment } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { catMap } from "../utils/maps"
// import Image from "gatsby-image"


function Category() {
  return (
    <StaticQuery
      query={catQuery}
      render={({ allSitePage: { edges } }) => {
        // console.log(edges)
        const cats = edges
        .map(v => v.node.context)
        .sort((a,b) => b.list.length - a.list.length)

        return (
          <Fragment>
            <h4>所有分类</h4>
            <ul>
              {
                cats.map((v, i) => (
                  <li key={i}>
                    <Link to={`/list/${v.cat}/`} >{catMap[v.cat]}</Link>
                    <span className="f-ml-05 g-tag g-tag-light">{v.list.length}</span>
                  </li>
                ))
              }
            </ul>
          </Fragment>
        )
      }}
    />
  )
}

const catQuery = graphql`
query {
  allSitePage(filter: {context: {iam: {eq: "post-category-list-page"}}}) {
    edges {
      node {
        id
        context {
          cat
          list {date}
        }
      }
    }
  }
}
`

export default Category

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import st from "./css/tag.module.scss"
import { tagColorMap } from "../utils/maps"

const Tag = ({ tag, size, isLink }) => {
  const style = {
    backgroundColor: tagColorMap[tag],
    fontSize: size + 'rem',
    borderRadius: `5px ${size * 1.5}rem 5px ${size * 1.5}rem`,
    padding: `0 ${0.8 * size}rem 0 ${1 * size}rem`,
  }

  if(isLink){
    return (
      <Link to={`/list/tag/${tag}/`} style={style} className={st.tag} >
        {tag}
      </Link>
    )
  }

  return (
    <b style={style} className={st.tag} >
      {tag}
    </b>
  )
}

Tag.propTypes = {
  tag: PropTypes.string,
  size: PropTypes.number,
  isLink: PropTypes.bool,
}

Tag.defaultProps = {
  tag: ``,
  size: 0.8,
  isLink: true,
}

export default Tag;